/**
 * Config
 */

const environment =
  process.env.PUBLIC_URL ||
  process.env.REACT_APP_PUBLIC_URL ||
  'http://localhost:8000'

const config = {
  api: {
    base: environment + '/api/',
    //base: 'http://localhost:8000/api/',
    version: 'v1',
  },
  lang: {
    version: '0.1',
    default: 'en',
    fallback: 'en',
    languageKey: 'lang',
    cachePrefix: 'i18next_res_',
    expirationTime: 1000, // TODO: Update for production (should we use infinite to only expire on version change?)
    localesPath: '/locales/{{lng}}/{{ns}}.json',
  },
  log: {
    redux: false,
  },
  versionPathname: {
    v2: '/v2',
  },
  refreshInterval: 1000 * 60,
  idleTimeout: 1000 * 60 * 60,
  plausible: {
    enabled: !!process.env.REACT_APP_PLAUSIBLE_ENABLED,
    domain: process.env.REACT_APP_PLAUSIBLE_DOMAIN || window.location.hostname,
    src:
      process.env.REACT_APP_PLAUSIBLE_SRC ||
      'https://plausible.io/js/script.exclusions.js',
  },
}

export default config
